import React from "react";
import "./Info.css";
import { useMediaQuery } from "react-responsive";
import { PrimaryButton } from "./CTAButtons";
import { useNavigate } from "react-router";

const MobileScreenInfo = () => {
  const navigate = useNavigate("/");
  return (
    <div className="bg-black w-full">
      <div
        className="text-white text-2xl text-center font-normal pt-12"
        style={{ fontFamily: "Montserrat" }}
      >
        OUR LEGACY
      </div>
      <div className="grid grid-cols-2 gap-4 px-6 mt-8">
        <div className="overflow-y-auto">
          <div
            className="text-white list-disc text-xs mt-0"
            style={{ fontFamily: "Montserrat" }}
          >
            ACETECH, Asia’s largest symposium on Architecture, Building
            materials, Interior, and Design. With a rich legacy of 17 years,
            this flourishing initiative of building a pivotal platform of
            national stature has transformed into now, an ultimate “One-Stop
            Solution” for Young and Developing India. Our prestigious event,
            consecutively, attracts esteemed national and international brands,
            distinguished architects, real estate developers, hoteliers, equity
            investors, builders, engineers, and designers from across the
            globe. 
          </div>
        </div>
        <div className="">
          <img
            src={
              "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694606454/AceTech%20Website/Rectangle_190_v3to2x.jpg"
            }
            alt=""
            className="w-36 h-44 m-auto"
          />
        </div>
        <PrimaryButton
          onClick={() => navigate("/contact")}
          className="mb-12"
          style={{ fontFamily: "Montserrat" }}
        >
          Enquire Now
        </PrimaryButton>
      </div>

      <div id="d-wrapperM">
        <div className="zig-zag-topM">
          <div
            className="text-white text-2xl text-center font-normal pt-0"
            style={{ fontFamily: "Montserrat" }}
          >
            OUR VISION
          </div>
          <div
            className="w-48 h-52"
            style={{ margin: "auto", marginTop: "20px" }}
          >
            <img
              src={
                "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694606670/AceTech%20Website/Rectangle_186_uyeinj.jpg"
              }
              alt=""
            />
          </div>
          <div
            className=""
            style={{
              paddingTop: "1rem",
              paddingLeft: "2.2rem",
              paddingRight: "2.2rem",
            }}
          >
            <div>
              <div
                className="m-auto text-white text-center font-normal text-xs mt-0"
                style={{
                  marginTop: "30px",
                  fontFamily: "Montserrat",
                }}
              >
                Our vision is to keep on excelling as the market leader in the
                Indian subcontinent for trade fairs. To achieve this, we will
                continue to focus on innovation by developing client-centric,
                ROI-based marketing and sales platforms. We aim to enhance our
                position as the foremost trade fair organizers, ensuring
                sustainable revenue growth and improved profits for our existing
                trade fairs.
              </div>
            </div>
            <div className="grid justify-center">
              <PrimaryButton
                onClick={() => navigate("/contact")}
                className=""
                style={{ fontFamily: "Montserrat" }}
              >
                Enquire Now
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      <div
        className="text-white text-2xl text-center font-normal pt-8"
        style={{ fontFamily: "Montserrat" }}
      >
        OUR MISSION
      </div>
      <div className="w-48 h-52" style={{ margin: "auto", marginTop: "30px" }}>
        <img
          src={
            "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694606745/AceTech%20Website/Rectangle_189_j2x7vg.jpg"
          }
          alt=""
          className="m-auto"
        />
      </div>
      <div
        className="py-8"
        style={{
          paddingTop: "1rem",
          paddingBottom: "1rem",
          paddingLeft: "2.2rem",
          paddingRight: "2.2rem",
        }}
      >
        <div>
          <div
            className="text-white text-center font-normal text-xs mt-0"
            style={{
              marginTop: "20px",
              fontFamily: "Montserrat",
            }}
          >
            Our mission is to offer comprehensive marketing solutions to
            corporations by establishing dynamic marketplaces through trade
            fairs, roadshows, networking events, and conferences. We aim to
            continuously adapt and evolve in response to our client’s needs,
            integrating social media and developing virtual marketplaces to
            further extend our reach for the benefit of our stakeholders and
            partners.
          </div>
          <div className="grid justify-center">
            <PrimaryButton
              onClick={() => navigate("/contact")}
              className="mb-4"
              style={{ fontFamily: "Montserrat" }}
            >
              Enquire Now
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Info = () => {
  const isLaptopScreen = useMediaQuery({ minWidth: 768 });
  const isTabScreen = useMediaQuery({ minWidth: 769 });

  const navigate = useNavigate();

  return (
    <>
      {isLaptopScreen ? (
        <div className="bg-black w-full" style={{ borderColor: "#111" }}>
          <div className="grid grid-cols-6">
            <div className="md:py-8 md:pl-6 lg:py-16 lg:pl-12 md:col-span-3 lg:col-span-2">
              <img
                src={
                  "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694606454/AceTech%20Website/Rectangle_190_v3to2x.jpg"
                }
                alt=""
                className="md:w-full md:h-auto lg:w-auto lg:h-auto m-auto"
              />
            </div>
            <div className="md:col-span-3 lg:col-span-4">
              <div className="md:px-10 md:py-14 lg:px-40 lg:py-20">
                <div
                  className="text-white md:text-3xl lg:text-5xl font-normal mt-2"
                  style={{ fontFamily: "Montserrat" }}
                >
                  OUR LEGACY
                </div>
                <div
                  className="text-white md:text-base lg:text-xl mt-8"
                  style={{ fontFamily: "Montserrat" }}
                >
                  ACETECH, Asia’s largest symposium on Architecture, Building
                  materials, Interior, and Design. With a rich legacy of 17
                  years, this flourishing initiative of building a pivotal
                  platform of national stature has transformed into now, an
                  ultimate “One-Stop Solution” for Young and Developing India.
                  Our prestigious event, consecutively, attracts esteemed
                  national and international brands, distinguished architects,
                  real estate developers, hoteliers, equity investors, builders,
                  engineers, and designers from across the globe.
                </div>
                <div
                  className="text-white md:text-base lg:text-xl mt-8 md:hidden"
                  style={{ fontFamily: "Montserrat" }}
                >
                  ACETECH strives to create a lasting impression on the
                  decision-makers of the architectural industry. At ACETECH, our
                  main aim is to create a thriving platform for businesses.
                  Through meticulous planning and execution, we guarantee
                  returns on investment, boost sales, elevate brand visibility,
                  and foster community engagement. Our ultimate objective is to
                  empower businesses and lead them to success in the rapidly
                  changing industry.
                </div>
                <PrimaryButton
                  onClick={() => navigate("/contact")}
                  style={{ fontFamily: "Montserrat" }}
                >
                  Enquire Now
                </PrimaryButton>
              </div>
            </div>
          </div>

          {/* zigzag */}

          <div id="d-wrapper">
            <div className="zig-zag-top">
              <div
                className="grid grid-cols-6 relative"
                style={{
                  paddingTop: `${isTabScreen ? "80px" : "0px"}`,
                  paddingBottom: `${isTabScreen ? "80px" : "0px"}`,
                }}
              >
                <div className="col-span-4 md:w-full">
                  <div
                    className="px-40 py-16"
                    style={{
                      paddingTop: `${isTabScreen ? "2rem" : "0rem"}`,
                      paddingBottom: "4rem",
                      paddingLeft: "5rem",
                      paddingRight: `${isTabScreen ? "15rem" : "5rem"}`,
                    }}
                  >
                    <div
                      className="text-white md:text-3xl lg:text-5xl font-normal lg:ml-20"
                      style={{ fontFamily: "Montserrat" }}
                    >
                      OUR Vision
                    </div>
                    <div
                      className="text-white md:text-base lg:text-xl md:w-full"
                      style={{
                        marginTop: "40px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Our vision is to keep on excelling as the market leader in
                      the Indian subcontinent for trade fairs. To achieve this,
                      we will continue to focus on innovation by developing
                      client-centric, ROI-based marketing and sales platforms.
                      We aim to enhance our position as the foremost trade fair
                      organizers, ensuring sustainable revenue growth and
                      improved profits for our existing trade fairs.
                    </div>
                    <button
                      onClick={() => navigate("/contact")}
                      className="w-48 h-11 text-white m-auto rounded-[30px] text-2xl font-normal bg-[#EC008C] hover:bg-opacity-80"
                      style={{
                        marginTop: "50px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Enquire Now
                    </button>
                  </div>
                </div>
                <div
                  className="lg:w-auto lg:h-auto md:w-72 md:h-80 pl-14 col-span-2 absolute"
                  style={{
                    top: "-160px",
                    right: "4%",
                  }}
                >
                  <img
                    src={
                      "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694606670/AceTech%20Website/Rectangle_186_uyeinj.jpg"
                    }
                    alt=""
                    className="m-auto"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* zigzag */}

          <div className="grid grid-cols-2 py-12 px-24 relative">
            <div
              className=""
              style={{ position: "relative", top: "-90px", left: "0px" }}
            >
              <img
                src={
                  "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694606745/AceTech%20Website/Rectangle_189_j2x7vg.jpg"
                }
                alt=""
                className="m-auto"
              />
            </div>
            <div className="ml-10">
              <div
                className="text-white md:text-3xl lg:text-5xl font-normal md:mt-4 lg:mt-20"
                style={{ fontFamily: "Montserrat" }}
              >
                OUR MISSION
              </div>
              <div
                className="text-white md:text-base lg:text-xl mt-8"
                style={{ fontFamily: "Montserrat" }}
              >
                Our mission is to offer comprehensive marketing solutions to
                corporations by establishing dynamic marketplaces through trade
                fairs, roadshows, networking events, and conferences. We aim to
                continuously adapt and evolve in response to our client’s needs,
                integrating social media and developing virtual marketplaces to
                further extend our reach for the benefit of our stakeholders and
                partners.
              </div>
              <PrimaryButton
                style={{ fontFamily: "Montserrat" }}
                onClick={() => navigate("/contact")}
              >
                Enquire Now
              </PrimaryButton>
            </div>
          </div>
        </div>
      ) : (
        <MobileScreenInfo />
      )}
    </>
  );
};
