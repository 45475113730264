import React from "react";
import kotra from "../assets/kotra.png";
import credai from "../assets/credai.png";
import gbsa from "../assets/GBSA.png";
import iici from "../assets/iicci.png";
import google from "../assets/google.png";
import microsoft from "../assets/microsoft.png";
import cocacola from "../assets/Cocacola.png";
import cisco from "../assets/CISCO.png";
import iacc from "../assets/IAACC.png";
import { PrimaryButton } from "./CTAButtons";
import { useNavigate } from "react-router";

const ImageCard = ({ img }) => {
  return (
    <div
      className="h-14 lg:h-20 rounded-xl lg:rounded-3xl w-auto"
      style={{
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
    >
      <img
        src={img}
        alt={`${img}.png`}
        className="w-full h-full object-contain py-2 px-5"
      />
    </div>
  );
};

export const Partners = () => {
  const navigate = useNavigate();
  return (
    <div className="pt-5 lg:pt-12 pb-5 lg:pb-20 h-auto">
      <div className="w-3/4 lg:w-2/3 font-normal text-3xl lg:text-5xl text-center m-auto font-Playfair-Display">
        International & Media partners
      </div>
      <div className="w-full px-4 lg:w-3/4 m-auto text-center text-xs lg:text-xl font-normal mt-4 font-Montserrat">
        Our unparalleled and impeccable ensemble of international partners,
        complemented by esteemed media affiliations, stands as a testament to
        our global excellence and reach.
      </div>
      {/* <div className="w-full mx-auto grid grid-cols-2 gap-6 px-4 py-4 lg:grid-cols-4 lg:gap-20 mt-8 lg:mt-20">
        <ImageCard img={kotra} />
        <ImageCard img={credai} />
        <ImageCard img={gbsa} />
        <ImageCard img={iici} />
        <ImageCard img={google} />
        <ImageCard img={microsoft} />
        <ImageCard img={cocacola} />
        <ImageCard img={iici} />
        <ImageCard img={cisco} />
        <ImageCard img={iacc} />
        <ImageCard img={iici} />
      </div> */}
      <div className="flex mb-0 lg:-mb-20">
        <div className="mx-auto ">
          <PrimaryButton onClick={() => navigate("/contact")}>
            Enquire Now
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
