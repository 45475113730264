import React, { useEffect, useState } from "react";

import Bg from "../../assets/Shuvam/contact-bg.png";
import axios from "axios";
import { FaStar } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";

import { Footer } from "../Footer";
import { Navbar } from "../Navbar";
import { PrimaryButton } from "../CTAButtons";
import Svg1 from "../../assets/Shuvam/SVG/Group 2111.svg";
import Svg2 from "../../assets/Shuvam/SVG/Vector-1.svg";
import Svg3 from "../../assets/Shuvam/SVG/Vector-2.svg";
import Svg4 from "../../assets/Shuvam/SVG/Vector-3.svg";
import Svg5 from "../../assets/Shuvam/SVG/Vector-4.svg";
import Svg6 from "../../assets/Shuvam/SVG/Vector.svg";
import ThumbsUp from "../../assets/Shuvam/ThumbsUp.png";

const Card = ({ Icon, text, backText }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  // const handleMoreClick = () => {
  //   setIsFlipped(!isFlipped);
  // };
  return (
    <div
      className={`card-mobile ${isFlipped ? "flipped-mobile" : ""}`}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="flipper-mobile">
        <div className="front-mobile">
          <div className="w-[176px] h-[270px] bg-gradient-to-b from-opacity-58 to-opacity-0 from-white to-[#e5e1e1] bg-opacity-25 border-[1px] border-[#848484] rounded-[15px] relative">
            <div className="w-[67px] h-[67px] flex justify-center items-center bg-[#F5F3F3] absolute -top-8 left-1/2 transform -translate-x-1/2 rounded-full drop-shadow-md">
              <img src={Icon} alt="" className="h-[30px] w-[30px]" />
            </div>
            <div className="flex flex-col justify-center items-center gap-5 w-full">
              <div className="text-sm font-[500] font-Montserrat px-5 h-[80px] mt-10">
                {text}
              </div>
              <button className="w-[82px] h-[29px] border-[1px] border-[#444444] rounded-[30px] font-[400] text-[15px] leading-[23px] font-Jacques-Francois mt-7">
                More
              </button>
            </div>
          </div>
        </div>
        <div className="back-mobile">
          <div className="w-[176px] h-[270px] bg-gradient-to-b from-opacity-58 to-opacity-0 from-white to-[#e5e1e1] bg-opacity-25 border-[1px] border-[#848484] rounded-[15px] relative">
            <div className="text-xs font-[500] font-Montserrat px-3 h-[80px] mt-10">
              {backText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedbackModal = ({ isOpen, onClose }) => {
  const [formPage, setFormPage] = useState(true);
  const [rating, setRating] = useState(0);
  const [name, setName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [feedback, setFeedback] = React.useState("");

  if (!isOpen) return null;

  const handleStarClick = (newRating) => {
    setRating(newRating);
  };

  const isSubmitDisabled = rating === 0 || !name || !companyName || !feedback;

  const handleFormSubmit = () => {
    const data = {
      Name: name,
      "Company Name": companyName,
      Feedback: feedback,
      Stars: rating,
    };
    const formArray = [];
    formArray.push(data);
    try {
      formArray.forEach((data) => {
        if (data) {
          axios
            .post(
              "https://sheet.best/api/sheets/e2fd2813-91c9-4053-8395-2ed8b6232ff3",
              data
            )
            .then(() => console.log(data))
            .catch((err) => console.error(err));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex lg:hidden items-center justify-center z-50 h-screen bg-black bg-opacity-70">
        {formPage ? (
          <div className="w-[90%] h-auto py-10 border-[1px] border-[#A4A4A4] rounded-[20px] flex flex-col justify-center items-center gap-5 bg-black bg-opacity-40 relative">
            <RxCross1
              className="absolute top-5 right-3 h-7 w-7"
              color="white"
              onClick={onClose}
            />
            <h1 className="text-[40px] leading-[52px] font-[400] font-Playfair-Display text-center text-white">
              Rate Us
            </h1>
            <div className="flex flex-col gap-8 justify-center items-center">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                className="w-[18em] h-[3em] border-[1px] border-[#999999] rounded-[10px] bg-inherit px-5 placeholder:text-white placeholder:font-Playfair-Display text-white"
                placeholder="Name"
              />
              <input
                type="text"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
                className="w-[18em] h-[3em] border-[1px] border-[#999999] rounded-[10px] bg-inherit px-5 placeholder:text-white placeholder:font-Playfair-Display text-white"
                placeholder="Company Name"
              />
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                type="text"
                className="w-[18em] h-[6em]  border-[1px] border-[#999999] rounded-[10px] bg-inherit px-5 pt-5 placeholder:text-white placeholder:font-Playfair-Display text-white"
                placeholder="Type something for your feedback..."
              />
            </div>

            <div className="flex flex-col gap-5 items-center ">
              <h1 className="font-[400] text-2xl font-Playfair-Display text-center text-white">
                Rating
              </h1>
              <div className="flex gap-8">
                {[1, 2, 3, 4, 5].map((star) => (
                  <FaStar
                    key={star}
                    className="w-7 h-7"
                    color={star <= rating ? "#FFD700" : "white"}
                    onClick={() => handleStarClick(star)}
                  />
                ))}
              </div>
              <button
                type="submit"
                className={`w-[80%] h-auto py-2 rounded-[10px] text-white font-[400] text-[24px] leading-[31px] font-Playfair-Display bg-[#EC008C] ${
                  isSubmitDisabled ? "cursor-not-allowed opacity-50" : ""
                }`}
                onClick={() => {
                  handleFormSubmit();
                  setFormPage(false);
                }}
                disabled={isSubmitDisabled}
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-black bg-opacity-40 w-[90%] h-auto py-10 border-[1px] border-[#A4A4A4] rounded-[20px] flex flex-col justify-center items-center gap-10 relative">
            <RxCross1
              className="absolute top-5 right-3 h-7 w-7"
              color="white"
              onClick={onClose}
            />
            <h1 className="text-2xl font-[400] font-Playfair-Display text-center text-[#FFF]">
              Thank you for rating us!
            </h1>
            <img src={ThumbsUp} alt="thumbsup.png" className="w-28 h-28" />

            <div className="flex gap-8">
              {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                  key={star}
                  className="w-9 h-9"
                  color={star <= rating ? "#FFD700" : "white"}
                />
              ))}
            </div>

            <button
              className="w-[50%] h-auto  py-2 rounded-[10px] text-white font-[400] text-[24px] leading-[31px] font-Playfair-Display bg-[#EC008C]"
              onClick={onClose}
            >
              Go Back
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const MobileContact = ({ isModalOpen, handleCloseModal, handleOpenModal }) => {
  const [wantToRateUs, setWantToRateUs] = useState(true);

  const handleWantToRateUs = () => {
    setWantToRateUs(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <div
        className="h-screen flex flex-col lg:hidden"
        style={{
          backgroundImage: `url(${Bg})`,
        }}
      >
        <div className="h-[15%]">
          <Navbar />
        </div>
        <div className="flex-1 w-full h-full flex justify-center items-center">
          <div className="w-full h-[525px] bg-white flex flex-col gap-3 justify-center items-center mt-32 m-16 rounded-xl">
            <h1 className="font-[400] text-[26px] leading-[15px] font-Jacques-Francois">
              WE'D LOVE TO HELP
            </h1>
            <div className="w-full flex justify-center">
              <div className="border-t border-gray-500/80 w-[300px]"></div>
            </div>
            <div>
              <h2 className="font-Jacques-Francois font-[400] text-[13px] leading-[15px]">
                Let’s get in touch
              </h2>
            </div>

            <div className="flex flex-col gap-8 mt-8">
              <div className="flex gap-5 mx-6">
                <div className="flex flex-col gap-2">
                  <label className="font-[400] text-[13px] leading-[15px] self-start font-Jacques-Francois">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="w-[136px] h-[45px] border-[1px] border-[#0C0C0C] border-opacity-20"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <label className="font-[400] text-[13px] leading-[15px] self-start font-Jacques-Francois">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="w-[136px] h-[45px] border-[1px] border-[#0C0C0C] border-opacity-20"
                  />
                </div>
              </div>

              <div className="flex gap-5 mx-6">
                <div className="flex flex-col gap-2">
                  <label className="font-[400] text-[13px] leading-[15px] self-start font-Jacques-Francois">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className="w-[136px] h-[45px] border-[1px] border-[#0C0C0C] border-opacity-20"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <label className="font-[400] text-[13px] leading-[15px] self-start font-Jacques-Francois">
                    Location
                  </label>
                  <input
                    type="text"
                    className="w-[136px] h-[45px] border-[1px] border-[#0C0C0C] border-opacity-20"
                  />
                </div>
              </div>

              <div className="flex gap-5 mx-6">
                <div className="flex flex-col gap-2">
                  <label className="font-[400] text-[13px] leading-[15px] self-start font-Jacques-Francois">
                    Email Address
                  </label>
                  <input
                    type="text"
                    className="w-[136px] h-[45px] border-[1px] border-[#0C0C0C] border-opacity-20"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <label className="font-[400] text-[13px] leading-[15px] self-start font-Jacques-Francois">
                    Additional Address
                  </label>
                  <input
                    type="text"
                    className="w-[136px] h-[45px] border-[1px] border-[#0C0C0C] border-opacity-20"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center">
                <PrimaryButton>Send</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-slate-200 h-fit pt-10 pb-20">
          <h1
            className="font-[400] text-[28px] leading-[16px]
        font-Jacques-Francois text-center"
          >
            FAQ's
          </h1>
          <div className="w-full flex justify-center mt-5">
            <div className="border-t border-gray-500/80 w-[200px]"></div>
          </div>

          <h2 className="font-[400] h-[14px] leading-[16px] font-Jacques-Francois mt-3 flex justify-center items-center">
            Most Frequently Asked Questions
          </h2>

          <div className="flex gap-2 justify-center mt-16 w-full">
            <Card
              Icon={Svg2}
              text={
                "I want to visit the exhibition. How can I register for it ?"
              }
              backText={
                "You can register for the expo through the link on our website or contact our registration team directly."
              }
            />

            <Card
              Icon={Svg1}
              text={
                "Is there an entrance payable to be paid to visit the exhibition?"
              }
              backText={
                "This exhibition is open to trade visitors and there is a nominal fee applicable for visitors in all  four cities of ACETECH."
              }
            />
          </div>

          <div className="flex gap-2 justify-center mt-24 w-full">
            <Card
              Icon={Svg3}
              text={"What are the dates and location of the expo?"}
              backText={
                "The event dates and location are available on our website. Please check the schedule for specific details."
              }
            />

            <Card
              Icon={Svg6}
              text={
                "Is the exhibition open to both  trade visitors and the general public?"
              }
              backText={
                "The exhibition is open for trade visitors as well as the general public who are interested in acquiring knowledge about latest product innovations and new launches in the market and purchasing from the suppliers in the building materials segment."
              }
            />
          </div>

          <div className="flex gap-2 justify-center mt-24 w-full">
            <Card
              Icon={Svg4}
              text={
                "Can I Enter And Exit The Exhibition More Than Once With My Ticket?"
              }
              backText={"Yes, re-entries are allowed."}
            />

            <Card
              Icon={Svg5}
              text={"What Are The Hours Of The Exhibition?"}
              backText={
                "The exhibition is open from 10 am – 7 pm on the scheduled exhibition dates and 10 am to 5.30 pm on the last day of the exhibition."
              }
            />
          </div>
        </div>

        <div className="h-[505px] flex flex-col">
          <Footer />
        </div>
      </div>
      {wantToRateUs && (
        <div className="fixed right-5 bottom-5 w-[203px] h-[94px] bg-[#FFFFFF] bg-opacity-100 rounded-[16px] flex lg:hidden flex-col justify-center items-center gap-2 border-2 border-[#EC008C]">
          <div className="relative">
            <h1 className="text-lg font-[400] font-Playfair-Display">
              Want to rate us?
            </h1>
            <div className="absolute -right-6 top-1 rounded-full bg-[#EC008C] bg-opacity-30 p-1">
              <RxCross1
                className="w-2 h-2"
                onClick={handleWantToRateUs}
                color="white"
              />
            </div>
          </div>

          <div className="flex gap-3">
            <FaStar className="w-[15px] h-[15px]" />
            <FaStar className="w-[15px] h-[15px]" />
            <FaStar className="w-[15px] h-[15px]" />
            <FaStar className="w-[15px] h-[15px]" />
            <FaStar className="w-[15px] h-[15px]" />
          </div>
          <button
            className="w-[10em] h-auto py-1 rounded-xl font-Playfair-Display text-center font-[400] text-sm bg-[#EC008C] text-white mb-1"
            onClick={handleOpenModal}
          >
            Submit Feedback
          </button>
        </div>
      )}

      {isModalOpen && (
        <FeedbackModal isOpen={isModalOpen} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default MobileContact;
