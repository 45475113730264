import React from "react";

export const PrimaryButton = ({ children, onClick,className }) => {
  return (
    <button
      onClick={onClick}
      className={`text-lg text-white w-32 h-9 mt-6 mr-2 sm:mb-5 font-medium font-Playfair-Display lg:w-56 lg:h-12 lg:text-2xl lg:mt-8 rounded-[36px] bg-[#EC008C] hover:bg-opacity-80 ${className}`}
    >
      {children}
    </button>
  );
};

export const SecondaryButton = ({ children, onClick,className }) => {
  return (
    <button
      onClick={onClick}
      className={`text-lg text-white w-32 h-9 mt-6 mr-2 sm:mb-5 font-medium lg:w-56 lg:h-12 lg:text-2xl lg:mt-8 font-Playfair-Display border-[#F9F9F9] bg-slate-200 bg-opacity-20 hover:bg-opacity-10 border-[1px] rounded-[36px] ${className}`}
    >
      {children}
    </button>
  );
};
