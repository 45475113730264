/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { PrimaryButton, SecondaryButton } from "../Components/CTAButtons";
import { Navbar } from "./Navbar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const HeroComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-black flex flex-col justify-center h-[85vh] lg:h-screen">
      <video
        preload="auto"
        autoPlay="autoplay"
        loop
        muted
        playsInline
        className="h-full object-cover bg-black opacity-50"
      >
        <source
          src="https://res.cloudinary.com/da05bn5ub/video/upload/q_auto/v1695044219/videoplayback_6_f4herl.mp4"
          type="video/mp4"
        />
      </video>

      <div className="absolute z-20 top-0 left-0 w-full h-full">
        <div className="fixed w-full ">
          <Navbar />
        </div>
        <div className="h-full grid justify-center items-center">
          <div className="w-full text-center p-2 text-white">
            <div className="text-4xl font-bold lg:text-7xl font-Playfair-Display">
              ACETECH
            </div>

            <div className="text-base w-full m-auto font-medium text-center mt-6 md:w-4/5 lg:text-2xl lg:w-9/12 font-Montserrat">
              Asia’s Largest Architecture, Interior Design & Building Materials
              Exhibition.
            </div>
            <PrimaryButton onClick={() => navigate("/contact")}>
              Enquire Now
            </PrimaryButton>
            <SecondaryButton>
              <Link to="https://online.fliphtml5.com/cyizs/yntm/#p=1">
                Brochure
              </Link>
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
