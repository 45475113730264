import './App.css';
import { AllRoutes } from './Components/AllRoutes';


function App() {
  return (
    <div>
      {/* <div>
        <header>

Test
        </header>
      </div> */}
      <AllRoutes />
    </div>
  );
}

export default App;
