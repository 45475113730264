// ErrorPage.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../Components/CTAButtons";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 font-Playfair-Display">
      <h1 className="text-4xl font-bold text-red-500 mb-4">404 - Not Found</h1>
      <p className="text-lg text-gray-600">
        The page you're looking for doesn't exist.
      </p>
      <PrimaryButton
        onClick={() => {
          // Replace with the actual URL of your homepage
          navigate("/");
        }}
      >
        Go to Homepage
      </PrimaryButton>
    </div>
  );
};

export default ErrorPage;
