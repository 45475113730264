import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";

import MobileContact from "../Components/CityPageComponents/MobileContact";
import { Footer } from "../Components/Footer";
import { Navbar } from "../Components/Navbar";
import ThumbsUp from "../assets/Shuvam/ThumbsUp.png";
import Svg1 from "../assets/Shuvam/SVG/Group 2111.svg";
import Svg2 from "../assets/Shuvam/SVG/Vector-1.svg";
import Svg3 from "../assets/Shuvam/SVG/Vector-2.svg";
import Svg4 from "../assets/Shuvam/SVG/Vector-3.svg";
import Svg5 from "../assets/Shuvam/SVG/Vector-4.svg";
import Svg6 from "../assets/Shuvam/SVG/Vector.svg";

import "../App.css";
import { PrimaryButton } from "../Components/CTAButtons";

const Card = ({ Icon, text, backText }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <div
      className={`card ${isFlipped ? "flipped" : ""}`}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="flipper">
        <div className="front">
          <div className="w-full h-full bg-gradient-to-b from-opacity-58 to-opacity-0 from-white to-[#e5e1e1] bg-opacity-25 border-[1px] border-[#848484] rounded-[15px] relative pt-20">
            <div className="w-[128px] h-[128px] flex justify-center items-center bg-[#F5F3F3] absolute -top-20 left-1/2 transform -translate-x-1/2 rounded-full drop-shadow-md">
              {/* <Icon className="h-[66px] w-[66px]" /> */}
              <img src={Icon} alt="" />
            </div>
            <div className="flex flex-col justify-center items-center gap-16 w-full">
              <p className="text-xl font-[500] font-Montserrat px-5 h-44">
                {text}
              </p>
              <button className="w-[40%] h-auto border-[1px] border-[#444444] rounded-full font-[400] text-3xl font-Playfair-Display py-1 text-center">
                More
              </button>
            </div>
          </div>
        </div>
        <div className="back">
          <div className="w-full h-full bg-gradient-to-b from-opacity-58 to-opacity-0 from-white to-[#e5e1e1] bg-opacity-25 border-[1px] border-[#848484] rounded-[15px] relative pt-20 text-center">
            <h1 className="text-xl font-[500] font-Montserrat px-5 h-44">
              {backText}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

const Input = ({ label, type }) => {
  return (
    <div className="flex flex-col gap-2">
      <label
        htmlFor={label}
        className="self-start ml-2 text-xl font-[400] leading-xl font-Playfair-Display"
      >
        {label}
      </label>
      <input
        type={type}
        className="border-2 border-[#DFDFDF] h-10 px-3 w-[14em]"
      />
    </div>
  );
};

//Modal

const FeedbackModal = ({ isOpen, onClose }) => {
  const [formPage, setFormPage] = useState(true);
  const [rating, setRating] = useState(0);
  const [name, setName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [feedback, setFeedback] = React.useState("");

  if (!isOpen) return null;

  const handleStarClick = (newRating) => {
    setRating(newRating);
  };

  const isSubmitDisabled = rating === 0 || !name || !companyName || !feedback;

  const handleFormSubmit = () => {
    const data = {
      Name: name,
      "Company Name": companyName,
      Feedback: feedback,
      Stars: rating,
    };
    const formArray = [];
    formArray.push(data);
    try {
      formArray.forEach((data) => {
        if (data) {
          axios
            .post(
              "https://sheet.best/api/sheets/e2fd2813-91c9-4053-8395-2ed8b6232ff3",
              data
            )
            .then(() => console.log(data))
            .catch((err) => console.error(err));
        }
      });

      // if (response.status === 200 || 201) {
      //   console.log(response.data);
      // } else {
      //   console.error("Error submitting feedback");
      // }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 lg:flex items-center justify-center z-50 h-screen bg-black bg-opacity-70 hidden">
        {formPage ? (
          <div className="w-[75%] h-auto py-10 border-[1px] border-[#A4A4A4] rounded-[20px] flex flex-col justify-center items-center gap-5 bg-black bg-opacity-40 relative">
            <RxCross1
              className="absolute top-5 right-3 h-10 w-10"
              color="white"
              onClick={onClose}
            />
            <h1 className="text-[40px] leading-[52px] font-[400] font-Playfair-Display text-center text-white">
              Rate Us
            </h1>
            <div className="flex flex-col gap-8">
              <div className="flex justify-between gap-5">
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="w-[18em] h-[3em] border-[1px] border-[#999999] rounded-[10px] bg-inherit px-5 placeholder:text-white placeholder:font-Playfair-Display text-white"
                  placeholder="Name"
                />
                <input
                  type="text"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  className="w-[18em] h-[3em] border-[1px] border-[#999999] rounded-[10px] bg-inherit px-5 placeholder:text-white placeholder:font-Playfair-Display text-white"
                  placeholder="Company Name"
                />
              </div>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                type="text"
                className="w-[42em] h-[6em]  border-[1px] border-[#999999] rounded-[10px] bg-inherit px-5 pt-5 placeholder:text-white placeholder:font-Playfair-Display text-white"
                placeholder="Type something for your feedback..."
              />
            </div>
            <div className="flex flex-col gap-5 items-center ">
              <h1 className="font-[400] text-2xl font-Playfair-Display text-center text-white">
                Rating
              </h1>
              <div className="flex gap-8">
                {[1, 2, 3, 4, 5].map((star) => (
                  <FaStar
                    key={star}
                    className="w-10 h-10"
                    color={star <= rating ? "#FFD700" : "white"}
                    onClick={() => handleStarClick(star)}
                  />
                ))}
              </div>
              <button
                type="submit"
                className={`w-[40%] h-auto py-2 rounded-[10px] text-white font-[400] text-[24px] leading-[31px] font-Playfair-Display bg-[#EC008C] ${
                  isSubmitDisabled ? "cursor-not-allowed opacity-50" : ""
                }`}
                onClick={() => {
                  handleFormSubmit();
                  setFormPage(false);
                }}
                disabled={isSubmitDisabled}
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-black bg-opacity-40 w-[60%] h-auto py-10 border-[1px] border-[#A4A4A4] rounded-[20px] flex flex-col justify-center items-center gap-10 relative">
            <RxCross1
              className="absolute top-5 right-3 h-10 w-10"
              color="white"
              onClick={onClose}
            />
            <h1 className="text-4xl font-[400] font-Playfair-Display text-center text-[#FFF]">
              Thank you for rating us!
            </h1>
            <img src={ThumbsUp} alt="thumbsup.png" className="w-40 h-40" />

            <div className="flex gap-8">
              {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                  key={star}
                  className="w-10 h-10"
                  color={star <= rating ? "#FFD700" : "white"}
                />
              ))}
            </div>

            <button
              className="w-[20%] h-auto  py-2 rounded-[10px] text-white font-[400] text-[24px] leading-[31px] font-Playfair-Display bg-[#EC008C]"
              onClick={onClose}
            >
              Go Back
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const Contact = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [wantToRateUs, setWantToRateUs] = useState(true);

  const handleWantToRateUs = () => {
    setWantToRateUs(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <div className="hidden lg:block">
        <div
          className="h-auto bg-cover bg-no-repeat overflow-auto"
          style={{
            backgroundImage: `url("https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694545216/AceTech%20Website/Contact/contact-bg_n0ub99.jpg")`,
          }}
        >
          <div className="h-[15%]">
            <Navbar />
          </div>
          <div className="flex-1 bg-white flex my-[8%] mx-[5%] pb-[1%]">
            <div className="w-[60%] flex flex-col gap-5 mt-16">
              <h1 className="text-[3em] leading-[1em] font-[400] font-Playfair-Display text-center">
                WE’D LOVE TO HELP
              </h1>
              <div className="w-full flex justify-center">
                <div className="border-t border-gray-500/80 w-[68%]"></div>
              </div>
              <div>
                <h1 className="text-xl leading-5 font-Playfair-Display font-[400] text-center">
                  Let’s get in touch
                </h1>
              </div>

              <div className="mt-5 flex flex-col gap-3  w-full h-auto">
                <div className="mx-auto flex gap-20">
                  <Input label={"First Name"} type={"text"} />
                  <Input label={"Last Name"} type={"text"} />
                </div>
                <div className="mx-auto flex gap-20">
                  <Input label={"Mobile Number"} type={"text"} />
                  <Input label={"Location"} type={"text"} />
                </div>
                <div className="mx-auto flex gap-20">
                  <Input label={"Email Address"} type={"email"} />
                  <Input label={"Addition Address"} type={"text"} />
                </div>
              </div>

              <div className="flex justify-center items-center  w-full mt-3">
                <PrimaryButton>Send</PrimaryButton>
              </div>
            </div>
            <div className="w-[40%]">
              <img
                src={
                  "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694545216/AceTech%20Website/Contact/contact-bg_n0ub99.jpg"
                }
                alt=""
                className="p-3 w-full h-full"
              />
            </div>
          </div>
        </div>

        <div className="bg-slate-200 pt-10 h-auto w-full">
          <div>
            <h1 className="font-[400] text-4xl font-Playfair-Display text-center">
              FAQs
            </h1>
            <div className="w-full flex justify-center mt-8">
              <div className="border-t border-gray-500/80 w-[30%]"></div>
            </div>
            <h1 className="font-[400] text-xl font-Playfair-Display mt-8 text-center">
              Most Frequently Asked Questions
            </h1>
            <div className="flex flex-col gap-40 mt-28 pb-28">
              <div className="flex gap-20 justify-center">
                <Card
                  Icon={Svg2}
                  text={
                    "I want to visit the exhibition. How can I register for it ?"
                  }
                  backText={
                    "You can register for the expo through the link on our website or contact our registration team directly."
                  }
                />
                <Card
                  Icon={Svg1}
                  text={"Is there an admission fee for the exhibition?"}
                  backText={
                    "This exhibition is open to trade visitors and there is a nominal fee applicable for visitors in all  four cities of ACETECH."
                  }
                />
                <Card
                  Icon={Svg3}
                  text={"What are the dates and location of the expo?"}
                  backText={
                    "The event dates and location are available on our website. Please check the schedule for specific details."
                  }
                />
              </div>
              <div className="flex gap-20 justify-center">
                <Card
                  Icon={Svg6}
                  text={
                    "Is the exhibition open to both  trade visitors and the general public?"
                  }
                  backText={
                    "The exhibition is open for trade visitors as well as the general public who are interested in acquiring knowledge about latest product innovations and new launches in the market and purchasing from the suppliers in the building materials segment."
                  }
                />
                <Card
                  Icon={Svg4}
                  text={
                    "Can I Enter And Exit The Exhibition More Than Once With My Ticket?"
                  }
                  backText={"Yes, re-entries are allowed."}
                />
                <Card
                  Icon={Svg5}
                  text={"What Are The Hours Of The Exhibition?"}
                  backText={
                    "The exhibition is open from 10 am – 7 pm on the scheduled exhibition dates and 10 am to 5.30 pm on the last day of the exhibition."
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />

        {wantToRateUs && (
          <div className="fixed right-5 bottom-5 w-[318px] h-[142px] bg-[#FFFFFF] bg-opacity-100 rounded-[16px] flex flex-col justify-center items-center gap-5 border-2 border-[#EC008C]">
            <div className="relative">
              <h1 className="text-[28px] leading-[37px] font-[400] font-Playfair-Display">
                Want to rate us?
              </h1>
              <div className="absolute -right-10 top-2 rounded-full bg-[#EC008C] bg-opacity-30 p-1">
                <RxCross1
                  className="w-3 h-3"
                  onClick={handleWantToRateUs}
                  color="white"
                />
              </div>
            </div>

            <div className="flex gap-3">
              <FaStar className="w-[24px] h-[24px]" />
              <FaStar className="w-[24px] h-[24px]" />
              <FaStar className="w-[24px] h-[24px]" />
              <FaStar className="w-[24px] h-[24px]" />
              <FaStar className="w-[24px] h-[24px]" />
            </div>
            <button
              className="w-[12em] h-auto py-1 rounded-xl font-Playfair-Display text-center font-[400] text-base bg-[#EC008C] text-white mb-1"
              onClick={handleOpenModal}
            >
              Submit Feedback
            </button>
          </div>
        )}
      </div>

      {isModalOpen && (
        <FeedbackModal isOpen={isModalOpen} onClose={handleCloseModal} />
      )}

      <>
        <MobileContact
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
        />
      </>
    </>
  );
};

export default Contact;
