import React, { useEffect } from "react";

import "../../Scroll.css";
import { Navbar } from "../Navbar";
import { PrimaryButton } from "../../Components/CTAButtons";

const CityImages = ({ handleComponentChange }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <Navbar />
      <div className="h-screen relative section">
        <img
          src={
            "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694610559/AceTech%20Website/City/Bangalore_i6fctt.jpg"
          }
          alt=""
          className="h-full w-full object-cover"
          width={700}
          height={700}
        />
        <div className="w-full h-full flex flex-col absolute left-0 top-0">
          <div className="flex-1">
            <div className="w-full h-full flex flex-col gap-0 lg:gap-8 justify-center items-center">
              <h1 className="text-[50px] lg:text-[100px] font-Playfair-Display font-[500] leading-[131.88px] text-[#F9F9F9]">
              BENGALURU
              </h1>
              <h2 className="text-[25px] lg:text-[50px] font-Montserrat font-[500] leading-[60px] text-[#F9F9F9]">
                6-8 October 2023
              </h2>
              <h2 className="text-[25px] lg:text-[35px] font-Montserrat font-[400] leading-[60px] text-[#F9F9F9] text-center">
              Bangalore International Exhibition Centre
              </h2>

              <PrimaryButton
                onClick={handleComponentChange}
                className="ml-0 lg:ml-8"
              >
                Know More
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      <div className="h-screen relative section">
        <img
          src={
            "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694610561/AceTech%20Website/City/Mumbai_bafwk5.jpg"
          }
          alt=""
          className="h-full w-full object-cover"
          width={700}
          height={700}
        />
        <div className="w-full h-full flex flex-col absolute left-0 top-0">
          <div className="flex-1">
            <div className="w-full h-full flex flex-col  gap-0 lg:gap-8 justify-center items-center">
              <h1 className="text-[50px] lg:text-[100px] font-Playfair-Display font-[500] leading-[131.88px] text-[#F9F9F9]">
                MUMBAI
              </h1>
              <h2 className="text-[25px] lg:text-[50px] font-Montserrat font-[500] leading-[60px] text-[#F9F9F9]">
                2-5 November 2023
              </h2>
              <h2 className="text-[25px] lg:text-[35px] font-Montserrat font-[400] leading-[60px] text-[#F9F9F9]">
                Bombay Exhibition Centre
              </h2>

              <PrimaryButton
                onClick={handleComponentChange}
                className="ml-0 lg:ml-8"
              >
                Know More
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      <div className="h-screen relative section">
        <img
          src={
            "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694610559/AceTech%20Website/City/Delhi_dswgyi.jpg"
          }
          alt=""
          className="h-full w-full object-cover"
          width={700}
          height={700}
        />
        <div className="w-full h-full flex flex-col absolute left-0 top-0">
          <Navbar />

          <div className="w-full h-full flex flex-col absolute left-0 top-0">
            <div className="flex-1">
              <div className="w-full h-full flex flex-col  gap-0 lg:gap-8 justify-center items-center">
                <h1 className="text-[50px] lg:text-[100px] font-Playfair-Display font-[500] leading-[131.88px] text-[#F9F9F9]">
                  DELHI
                </h1>
                <h2 className="text-[25px] lg:text-[50px] font-Montserrat font-[500] leading-[60px] text-[#F9F9F9]">
                  14-17 December 2023
                </h2>
                <h2 className="text-[25px] lg:text-[35px] font-Montserrat font-[400] leading-[60px] text-[#F9F9F9]">
                  Pragati Maidan
                </h2>

                <PrimaryButton
                  onClick={handleComponentChange}
                  className="ml-0 lg:ml-8"
                >
                  Know More
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-screen relative section">
        <img
          src={
            "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694610559/AceTech%20Website/City/Hyderabad_nc8j3h.jpg"
          }
          alt=""
          className="h-full w-full object-cover"
          width={700}
          height={700}
        />
        <div className="w-full h-full flex flex-col absolute left-0 top-0">
          <div className="flex-1">
            <div className="w-full h-full flex flex-col  gap-0 lg:gap-8 justify-center items-center">
              <h1 className="text-[50px] lg:text-[100px] font-Playfair-Display font-[500] leading-[131.88px] text-[#F9F9F9]">
                HYDERABAD
              </h1>
              <h2 className="text-[25px] lg:text-[50px] font-Montserrat font-[500] leading-[60px] text-[#F9F9F9]">
                19-21 January 2024
              </h2>
              <h2 className="text-[25px] lg:text-[35px] font-Montserrat font-[400] leading-[60px] text-[#F9F9F9]">
                Hitex Exhibition Centre
              </h2>

              <PrimaryButton
                onClick={handleComponentChange}
                className="ml-0 lg:ml-8"
              >
                Know More
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CityImages;
