import React, { useEffect, useState } from "react";

import { Booking, CityImages } from "../Components/CityPageComponents";

const City = () => {
  const [isCityComponent, setIsCityComponent] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const handleComponentChange = () => {
    setIsCityComponent(false);
  };
  return (
    <div>
      {isCityComponent ? (
        <CityImages handleComponentChange={handleComponentChange} />
      ) : (
        <Booking />
      )}
    </div>
  );
};

export default City;
