import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "../Pages/HomePage";
import { About } from "../Pages/About";
import City from "../Pages/CityPage";
import Contact from "../Pages/Contact";
import Sponsors from "../Pages/Sponsors";
import Error from "../Pages/Error";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/city" element={<City />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/sponsors" element={<Sponsors />} />
      <Route path="*" element={<Error/>} />
    </Routes>
  );
};
