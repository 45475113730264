/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import bgCover from "../assets/Rectangle 381.png";
import { Navbar } from "./Navbar";
import { PrimaryButton, SecondaryButton } from "../Components/CTAButtons";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const Number = () => {
  const [countE, setCountE] = useState(0);
  const [countDisplay, setCountDisplay] = useState(0);
  const [countVisitors, setCountVisitors] = useState(0);
  const [countArea, setCountArea] = useState(0);

  const countingRef = useRef(null);

  const navigate = useNavigate();

  //   exhibitors
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          let currentCountE = 0;
          const interval = setInterval(() => {
            if (currentCountE <= 4095) {
              setCountE(currentCountE);
              currentCountE += 45;
            } else {
              clearInterval(interval);
            }
          }, 5);

          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );

    if (countingRef.current) {
      observer.observe(countingRef.current);
    }

    return () => {
      if (countingRef.current) {
        observer.unobserve(countingRef.current);
      }
    };
  }, []);

  //   display
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          let currentCountD = 0;
          const interval = setInterval(() => {
            if (currentCountD <= 5500) {
              setCountDisplay(currentCountD);
              currentCountD += 50;
            } else {
              clearInterval(interval);
            }
          }, 5);

          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );

    if (countingRef.current) {
      observer.observe(countingRef.current);
    }

    return () => {
      if (countingRef.current) {
        observer.unobserve(countingRef.current);
      }
    };
  }, []);

  //visitors

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          let currentCountV = 0;
          const interval = setInterval(() => {
            if (currentCountV <= 867194) {
              setCountVisitors(currentCountV);
              currentCountV += 4402;
            } else {
              clearInterval(interval);
            }
          }, 0.00001);

          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );

    if (countingRef.current) {
      observer.observe(countingRef.current);
    }

    return () => {
      if (countingRef.current) {
        observer.unobserve(countingRef.current);
      }
    };
  }, []);

  //totalArea
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          let currentCountTA = 0;
          const interval = setInterval(() => {
            if (currentCountTA <= 2500000) {
              setCountArea(currentCountTA);
              currentCountTA += 10000;
            } else {
              clearInterval(interval);
            }
          }, 0.000000001);

          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );

    if (countingRef.current) {
      observer.observe(countingRef.current);
    }

    return () => {
      if (countingRef.current) {
        observer.unobserve(countingRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div className="w-full">
        <Navbar />
        <div
          className="h-screen grid justify-center items-center"
          style={{
            backgroundImage: `url('${bgCover}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="">
            <div className="text-center text-white">
              <div className="w-11/12 m-auto text-3xl font-normal lg:text-7xl lg:w-full font-Playfair-Display">
                ACETECH
              </div>
              <div
                className="text-base m-auto font-normal text-center mt-5 lg:text-xl w-4/5"
                style={{ fontFamily: "montserrat" }}
              >
                ACETECH, founded in 2006, started as a platform uniting industry
                professionals for product display and sourcing. Over time, it
                evolved into a premier hub for industry innovation and
                networking. Originally in Mumbai, it now spans major cities like
                Delhi, Bengaluru, and Hyderabad. ACETECH is a leading Indian
                trade fair in the Architecture, Building Materials and Interior
                Design industry and ranks as Asia’s largest and the world’s
                third-largest exhibition.
              </div>
              <PrimaryButton onClick={() => navigate("/contact")}>
                Chat with Us
              </PrimaryButton>
              <SecondaryButton>
                <Link to="https://online.fliphtml5.com/cyizs/yntm/#p=1">
                  Brochure
                </Link>
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 px-4 lg:py-10">
        <div className="w-full grid grid-cols-2 gap-6 lg:gap-10 lg:grid-cols-4">
          <div className="">
            <div
              ref={countingRef}
              className=" text-xl font-semibold text-center lg:text-4xl"
              style={{ fontFamily: "montserrat", color: "#2E2E2E9E" }}
            >
              {countE}+
            </div>
            <div
              className="text-center text-medium text-sm mt-1 lg:text-2xl lg:mt-4"
              style={{ fontFamily: "montserrat", color: "#2E2E2E9E" }}
            >
              Exhibitors
            </div>
          </div>

          <div className="">
            <div
              ref={countingRef}
              className="text-xl font-semibold text-center lg:text-4xl"
              style={{ fontFamily: "montserrat", color: "#2E2E2E9E" }}
            >
              {countDisplay}+
            </div>
            <div
              className="text-center text-medium text-sm mt-1 lg:text-2xl lg:mt-4"
              style={{ fontFamily: "montserrat", color: "#2E2E2E9E" }}
            >
              Brands On Display
            </div>
          </div>

          <div className="">
            <div
              ref={countingRef}
              className="text-xl font-semibold text-center lg:text-4xl"
              style={{ fontFamily: "montserrat", color: "#2E2E2E9E" }}
            >
              {countVisitors}+
            </div>
            <div
              className="text-center text-medium text-sm mt-1 lg:text-2xl lg:mt-4"
              style={{ fontFamily: "montserrat", color: "#2E2E2E9E" }}
            >
              Visitors
            </div>
          </div>
          <div className="-mt-2 p-2 grid justify-center items-center">
            <div
              ref={countingRef}
              className="flex text-xl font-semibold text-center items-center ml-6 lg:text-4xl"
              style={{ fontFamily: "montserrat", color: "#2E2E2E9E" }}
            >
              {countArea}
              <div className="text-sm lg:text-2xl">sq.Ft</div>
            </div>
            <div
              className="text-center text-medium text-sm mt-1 lg:text-2xl lg:mt-4"
              style={{ fontFamily: "montserrat", color: "#2E2E2E9E" }}
            >
              Total Exhibition Area
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Number;
