import React, { useRef, useState } from "react";
import "tailwindcss/tailwind.css";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import pm from "../assets/PM.png";
import nai from "../assets/Nai.png";
import dev from "../assets/Dev.png";
import chr from "../assets/chr.png";
import nir from "../assets/nir.png";
import haf from "../assets/haf.png";
import { PiArrowFatLinesDownFill } from "react-icons/pi";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";
import { PrimaryButton } from "./CTAButtons";
import { useNavigate } from "react-router";

const PM = () => {
  const navigate = useNavigate();
  const isLaptopScreen = useMediaQuery({ minWidth: 750 });
  return (
    <div
      className="h-screen grid grid-cols-3 lg:grid-cols-2 justify-between"
      style={{
        backgroundImage: `url('${
          isLaptopScreen
            ? "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694543829/AceTech%20Website/PM_Modi_wpknjt.jpg"
            : "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694543930/AceTech%20Website/PM2_rdlgxa.jpg"
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid items-center justify-between col-span-2 lg:col-span-1">
        <div className="flex">
          <div
            className="text-white pl-4 lg:pl-8 font-light text-7xl lg:text-[180px]"
            style={{
              fontFamily: "montserrat",
            }}
          >
            “
          </div>
          <div className="lg:bg-transparent bg-black bg-opacity-40 text-white font-normal text-base lg:px-4 lg:w-3/4 lg:text-2xl pt-6 lg:pt-16 w-full lg:w-1/2 font-Playfair-Display">
            ACETECH will provide a good platform for all the stakeholders of the
            construction industry for learning and sharing their experience and
            expertise.
          </div>
        </div>

        <div className="lg:bg-transparent bg-black bg-opacity-40 grid justify-start px-2 lg:px-28">
          <div className="text-white text-sm lg:text-3xl font-normal text-center pb-2 font-Playfair-Display">
            SHRI NARENDRA MODI
          </div>
          <hr
            className="w-11/12 m-auto lg:w-full"
            style={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
          />
          <div className="text-xs lg:text-lg text-white font-normal text-center pt-2 font-Playfair-Display">
            HONORABLE PRIME MINISTER OF INDIA
          </div>
        </div>
      </div>
      <div className=" grid justify-end items-end py-8 px-2 lg:px-12 lg:py-12">
        <PrimaryButton onClick={() => navigate("/contact")}>
          Enquire Now
        </PrimaryButton>
      </div>
    </div>
  );
};
const VPNaidu = () => {
  const navigate = useNavigate();
  const isLaptopScreen = useMediaQuery({ minWidth: 768 });
  return (
    <div
      className="h-screen grid grid-cols-3 lg:grid-cols-2 justify-between"
      style={{
        backgroundImage: `url('${
          isLaptopScreen
            ? "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694543988/AceTech%20Website/VP_Cover_cpwxug.jpg"
            : "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544021/AceTech%20Website/VP2_opmc9g.jpg"
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid items-center justify-between col-span-2 lg:col-span-1">
        <div className="flex">
          <div
            className="text-white pl-4 lg:pl-8 font-light text-7xl lg:text-[180px]"
            style={{
              fontFamily: "montserrat",
            }}
          >
            “
          </div>
          <div className="lg:bg-transparent bg-black bg-opacity-40 text-white font-normal text-base lg:px-4 lg:w-3/4 lg:text-2xl pt-6 lg:pt-16 w-full lg:w-1/2 font-Playfair-Display">
            I am really happy that a show of this stature has come up in India,
            we as the government will provide wholehearted support for it to
            grow to an international level.”
          </div>
        </div>

        <div className="lg:bg-transparent bg-black bg-opacity-40 w-3/4 m-auto grid justify-start px-2 lg:px-28">
          <div className="text-white text-sm lg:text-3xl font-normal text-center pb-2 font-Playfair-Display">
            SHRI VENKAIAH NAIDU
          </div>
          <hr
            className="w-11/12 m-auto lg:w-full"
            style={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
          />
          <div className="text-xs lg:text-lg text-white font-normal text-center pt-2 font-Playfair-Display">
            VICE PRESIDENT OF INDIA
          </div>
        </div>
      </div>
      <div className=" grid justify-end items-end py-8 px-2 lg:px-12 lg:py-12">
        <PrimaryButton onClick={() => navigate("/contact")}>Enquire Now</PrimaryButton>
      </div>
    </div>
  );
};
const DevComponent = () => {
  const navigate = useNavigate();
  const isLaptopScreen = useMediaQuery({ minWidth: 768 });
  return (
    <div
      className="h-screen grid grid-cols-3 lg:grid-cols-2 justify-between"
      style={{
        backgroundImage: `url('${
          isLaptopScreen
            ? "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544068/AceTech%20Website/former_CM_izzip2.jpg"
            : "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544098/AceTech%20Website/Dev2_wigqyk.jpg"
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid items-center justify-between col-span-2 lg:col-span-1">
        <div className="flex">
          <div
            className="text-white pl-4 lg:pl-8 font-light text-7xl lg:text-[180px]"
            style={{
              fontFamily: "montserrat",
            }}
          >
            “
          </div>
          <div className="lg:bg-transparent bg-black bg-opacity-20 text-white font-normal text-base lg:px-4 lg:w-3/4 lg:text-2xl pt-6 lg:pt-16 w-full lg:w-1/2 font-Playfair-Display">
            I would like to thank ACETECH for inviting me here and bringing all
            the industry professionals and government officials under one roof
            to discuss and deliberate a mutual agenda that would change the city
            and the industry for better.”
          </div>
        </div>

        <div className="lg:bg-transparent bg-black bg-opacity-40 grid justify-start px-2 lg:px-28">
          <div className="text-white text-sm lg:text-3xl font-normal text-center pb-2 font-Playfair-Display">
            SHRI DEVENDRA FADNAVIS
          </div>
          <hr
            className="w-11/12 m-auto lg:w-full"
            style={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
          />
          <div className="text-xs lg:text-lg text-white font-normal text-center pt-2 font-Playfair-Display">
            FORMER CHIEF MINISTER OF MAHARASHTRA
          </div>
        </div>
      </div>
      <div className=" grid justify-end items-end py-8 px-2 lg:px-12 lg:py-12">
        <PrimaryButton onClick={() => navigate("/contact")}>Enquire Now</PrimaryButton>
      </div>
    </div>
  );
};
const Chris = () => {
  const navigate = useNavigate();
  const isLaptopScreen = useMediaQuery({ minWidth: 768 });
  return (
    <div
      className="h-screen grid grid-cols-3 lg:grid-cols-2 justify-between"
      style={{
        backgroundImage: `url('${
          isLaptopScreen
            ? "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544141/AceTech%20Website/chrish_lldxuk.jpg"
            : "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544160/AceTech%20Website/chr2_yipiyp.jpg"
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid items-center justify-between col-span-2 lg:col-span-1">
        <div className="flex">
          <div
            className="text-white pl-4 lg:pl-8 font-light text-7xl lg:text-[180px]"
            style={{
              fontFamily: "montserrat",
            }}
          >
            “
          </div>
          <div className="lg:bg-transparent bg-black bg-opacity-40 text-white font-medium text-base lg:px-4 lg:w-3/4 lg:text-2xl pt-6 lg:pt-16 w-full lg:w-1/2 font-Playfair-Display">
            The size of the show and the influence it carries in India speaks
            for itself in a way. A lot of influential people and the industry
            leaders are here. When you bring all those people together for
            intelligent discussion and debates, it will only result in positive
            influence.
          </div>
        </div>

        <div className="lg:bg-transparent bg-black bg-opacity-40 grid justify-start px-2 w-3/4 m-auto lg:px-28">
          <div className="text-white text-sm lg:text-3xl font-normal text-center pb-2 font-Playfair-Display">
            AR. CHRIS BUBB
          </div>
          <hr
            className="w-11/12 m-auto lg:w-full"
            style={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
          />
          <div className="text-xs lg:text-lg text-white font-normal text-center py-2 font-Playfair-Display">
            PARTNER, FOSTERS+PARTNERS
          </div>
        </div>
      </div>
      <div className="grid justify-end items-end py-8 px-2 lg:px-12 lg:py-12">
        <PrimaryButton onClick={() => navigate("/contact")}>Enquire Now</PrimaryButton>
      </div>
    </div>
  );
};
const Nirnjan = () => {
  const navigate = useNavigate();
  const isLaptopScreen = useMediaQuery({ minWidth: 768 });
  return (
    <div
      className="h-screen grid grid-cols-3 lg:grid-cols-2 justify-between"
      style={{
        backgroundImage: `url('${
          isLaptopScreen
            ? "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544205/AceTech%20Website/niranjann_adgoti.jpg"
            : "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544249/AceTech%20Website/Nir2_offtyg.jpg"
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid items-center justify-between col-span-2 lg:col-span-1">
        <div className="flex">
          <div
            className="text-white pl-4 lg:pl-8 font-light text-7xl lg:text-[180px]"
            style={{
              fontFamily: "montserrat",
            }}
          >
            “
          </div>
          <div className="lg:bg-transparent bg-black bg-opacity-20 text-white font-normal text-base lg:px-4 lg:w-3/4 lg:text-2xl pt-6 lg:pt-16 w-full lg:w-1/2 font-Playfair-Display">
            I would like to thank ACETECH for inviting me here and bringing all
            the industry professionals and government officials under one roof
            to discuss and deliberate a mutual agenda that would change the city
            and the industry for better.”
          </div>
        </div>

        <div className="lg:bg-transparent bg-black bg-opacity-40 ml-4 grid justify-start px-2 lg:px-28">
          <div className="text-white text-sm lg:text-2xl font-normal text-center pb-2 font-Playfair-Display">
            DR. NIRANJAN HIRANANDANI
          </div>
          <hr
            className="w-11/12 m-auto lg:w-11/12"
            style={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
          />
          <div className="text-xs lg:text-lg text-white font-normal text-center pt-2 font-Playfair-Display">
            CO-FOUNDER & MANAGING DIRECTOR, HIRANANDANI GROUP
          </div>
        </div>
      </div>
      <div className=" grid justify-end items-end py-8 px-2 lg:px-12 lg:py-12">
        <PrimaryButton onClick={() => navigate("/contact")}>Enquire Now</PrimaryButton>
      </div>
    </div>
  );
};
const Hafeez = () => {
  const navigate = useNavigate();
  const isLaptopScreen = useMediaQuery({ minWidth: 768 });
  return (
    <div
      className="h-screen grid grid-cols-3 lg:grid-cols-2 justify-between"
      style={{
        backgroundImage: `url('${
          isLaptopScreen
            ? "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544305/AceTech%20Website/hafeezz_dz8gea.jpg"
            : "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694544367/AceTech%20Website/Haf2_zjbgeh.jpg"
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid items-center justify-between col-span-2 lg:col-span-1">
        <div className="flex">
          <div
            className="text-white pl-4 lg:pl-8 font-light text-7xl lg:text-[180px]"
            style={{
              fontFamily: "montserrat",
            }}
          >
            “
          </div>
          <div className="lg:bg-transparent bg-black bg-opacity-40 text-white font-normal text-base lg:px-4 lg:w-3/4 lg:text-2xl pt-6 lg:pt-16 w-full lg:w-1/2 font-Playfair-Display">
            ACETECH gets better every year. I have confidence in the organisers
            that this year too it would be better than the last year.
          </div>
        </div>

        <div className="lg:bg-transparent bg-black bg-opacity-40 ml-4 grid justify-start px-2 lg:px-28">
          <div className="text-white text-sm lg:text-3xl font-normal text-center pb-2 font-Playfair-Display">
            AR. HAFEEZ CONTRACTOR
          </div>
          <hr
            className="w-11/12 m-auto lg:w-full"
            style={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
          />
          <div className="text-xs lg:text-lg text-white font-normal text-center pt-2 font-Playfair-Display">
            PROPRIETOR, ARCHITECT HAFEEZ CONTRACTOR
          </div>
        </div>
      </div>
      <div className=" grid justify-end items-end py-8 px-2 lg:px-12 lg:py-12">
        <PrimaryButton onClick={() => navigate("/contact")}>Enquire Now</PrimaryButton>
      </div>
    </div>
  );
};

const PMTestimonial = () => {
  return (
    <div>
      <img src={pm} alt="" className="m-auto" />
      <div
        className="text-xs lg:text-sm font-medium text-center mt-6"
        style={{ fontFamily: "montserrat" }}
      >
        SHRI NARENDRA MODI
      </div>
      <div
        className="w-28 m-auto text-xs lg:text-xs font-normal text-center mt-2"
        style={{ fontFamily: "montserrat" }}
      >
        HONORABLE PRIME MINISTER OF INDIA
      </div>
    </div>
  );
};

const NaiTestimonial = () => {
  return (
    <div>
      <img src={nai} alt="" className="m-auto" />
      <div
        className="w-28 m-auto text-xs lg:text-xs font-medium text-center mt-6"
        style={{ fontFamily: "montserrat" }}
      >
        SHRI VENKAIAH NAIDU
      </div>
      <div
        className="w-28 m-auto text-xs lg:text-xs font-normal text-center mt-2"
        style={{ fontFamily: "montserrat" }}
      >
        HONORABLE VICE PRESIDENT OF INDIA
      </div>
    </div>
  );
};

const DevTestimonial = () => {
  return (
    <div>
      <img src={dev} alt="" className="m-auto" />
      <div
        className="w-28 m-auto text-xs lg:text-xs font-medium text-center mt-6"
        style={{ fontFamily: "montserrat" }}
      >
        SHRI DEVENDRA FADNAVIS
      </div>
      <div
        className="w-28 m-auto text-xs lg:text-xs font-normal text-center mt-2"
        style={{ fontFamily: "montserrat" }}
      >
        FORMER CHIEF MINISTER OF MAHARASHTRA
      </div>
    </div>
  );
};

const ChrTestimonial = () => {
  return (
    <div>
      <img src={chr} alt="" className="m-auto" />
      <div
        className="w-28 m-auto text-xs lg:text-xs font-medium text-center mt-6"
        style={{ fontFamily: "montserrat" }}
      >
        AR. CHRIS BUBB
      </div>
      <div
        className="w-28 m-auto text-xs lg:text-xs font-normal text-center mt-2"
        style={{ fontFamily: "montserrat" }}
      >
        PARTNER, FOSTERS+PARTNERS
      </div>
    </div>
  );
};

const NirTestimonial = () => {
  return (
    <div>
      <img src={nir} alt="" className="m-auto" />
      <div
        className="w-28 m-auto text-xs lg:text-xs font-medium text-center mt-6"
        style={{ fontFamily: "montserrat" }}
      >
        DR. NIRANJAN HIRANANDANI
      </div>
      <div
        className="w-28 m-auto text-xs lg:text-xs font-normal text-center mt-2"
        style={{ fontFamily: "montserrat" }}
      >
        CO-FOUNDER & MANAGING DIRECTOR, HIRANANDANI GROUP
      </div>
    </div>
  );
};

const HafTestimonial = () => {
  return (
    <div>
      <img src={haf} alt="" className="m-auto" />
      <div
        className="w-28 m-auto text-xs lg:text-xs font-medium text-center mt-6"
        style={{ fontFamily: "montserrat" }}
      >
        AR. HAFEEZ CONTRACTOR
      </div>
      <div
        className="w-28 m-auto text-xs lg:text-xs font-normal text-center mt-2"
        style={{ fontFamily: "montserrat" }}
      >
        PROPRIETOR, ARCHITECT HAFEEZ CONTRACTOR
      </div>
    </div>
  );
};

const Slider = () => {
  const slides = [
    <PM />,
    <VPNaidu />,
    <DevComponent />,
    <Chris />,
    <Nirnjan />,
    <Hafeez />,
  ];

  const testimonials = [
    <PMTestimonial />,
    <NaiTestimonial />,
    <DevTestimonial />,
    <ChrTestimonial />,
    <NirTestimonial />,
    <HafTestimonial />,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const componentRef = useRef(null);

  const scrollToComponent = () => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex flex-col items-center justify-center relative">
      <div className="relative w-full">
        <div className="w-full h-auto" ref={componentRef}>
          <Carousel
            showArrows={true}
            selectedItem={selectedItem}
            showIndicators={false}
            showStatus={false}
            onChange={(index) => setCurrentIndex(index)}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <div
                  onClick={onClickHandler}
                  title={label}
                  className="absolute left-0 z-10 top-1/2 transform -translate-y-1/2 w-10 h-10 text-white p-2 rounded-full cursor-pointer flex justify-center items-center"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.28)" }}
                >
                  <BsArrowLeft className="text-3xl" />
                </div>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <div
                  onClick={onClickHandler}
                  title={label}
                  className="absolute right-0 z-10 top-1/2 transform -translate-y-1/2 bg-gray-800 w-10 h-10  text-white p-2 rounded-full cursor-pointer flex justify-center items-center "
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.28)" }}
                >
                  <BsArrowRight className="text-3xl" />
                </div>
              )
            }
          >
            <PM />
            <VPNaidu />
            <DevComponent />
            <Chris />
            <Nirnjan />
            <Hafeez />
          </Carousel>
          <div>
            <div className="w-11/12 lg:w-1/2 m-auto relative flext justify-center bottom-16 lg:bottom-20">
              <div
                className="relative h-2 lg:h-4 w-full"
                style={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(197, 197, 197, 0.29)",
                }}
              >
                <div
                  className="absolute h-2 lg:h-4 bg-white"
                  style={{
                    width: `${(currentIndex + 1) * (100 / slides.length)}%`,
                    borderRadius: "30px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-2 lg:px-0">
        <div className="w-full text-2xl md:text-3xl lg:text-4xl font-medium text-center mt-10 font-Playfair-Display">
          MORE TESTIMONIALS
        </div>
        <div className="text-black text-3xl md:text-4xl lg:text-5xl mt-4 flex justify-center items-center">
          <PiArrowFatLinesDownFill />
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 md:gap-10 lg:grid-cols-6 lg:gap-16 mt-8 lg:mt-16">
          {testimonials.map((el, index) => (
            <div
              key={index}
              onClick={() => {
                setSelectedItem(index);
                scrollToComponent();
              }}
              className="cursor-pointer"
            >
              {el}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
