/* eslint-disable jsx-a11y/iframe-has-title */
import axios from "axios";
import React, { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp, BsTelephoneFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";

const MobileFooter = () => {
  return (
    <div
      className="w-full grid grid-cols-2 p-3"
      style={{ backgroundColor: "#303030" }}
    >
      <div className="cursor-pointer">
        <img
          src={
            "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694610018/AceTech%20Website/logo_footer_w8r6hv.png"
          }
          alt="logo"
          className="w-28 h-36 -mt-8"
        />
      </div>
      <div className="text-xs text-left font-meddium text-white font-Montserrat">
        530, Laxmi Plaza, Laxmi Industrial Estate, New Link Road, Andheri West,
        Mumbai – 400053, India
      </div>
      <div className="mt-1">
        <div className="flex justify-start items-center text-white mt-2">
          <a
            href="https://wa.me/+919833237174"
            target="_blank"
            rel="noreferrer"
          >
            <BsWhatsapp className="w-6 h-6" />
          </a>
          <div
            className="text-xs font-meddium ml-2"
            style={{
              fontFamily: "montserrat",
            }}
          >
            <a
              href="https://wa.me/+919833237174"
              target="_blank"
              rel="noreferrer"
            >
              +91 98332-37174
            </a>
          </div>
        </div>
        <div className="flex justify-start items-center text-white mt-2">
          <a href="tel:+919324553996" target="_blank" rel="noreferrer">
            <BsTelephoneFill className="w-6 h-6" />
          </a>
          <div
            className="text-xs font-meddium ml-2"
            style={{
              fontFamily: "montserrat",
            }}
          >
            <a href="tel:+919324553996" target="_blank" rel="noreferrer">
              +91 93245-53996
            </a>
          </div>
        </div>
        <div className="flex justify-start items-center text-white mt-2">
          <a href="mailto:miraz.q@abec.asia" target="_blank" rel="noreferrer">
            <AiOutlineMail className="w-6 h-6" />
          </a>
          <div
            className="text-xs font-meddium ml-2"
            style={{
              fontFamily: "montserrat",
            }}
          >
            <a href="mailto:miraz.q@abec.asia" target="_blank" rel="noreferrer">
              miraz.q@abec.asia
            </a>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15077.408793284514!2d72.8292998!3d19.1360545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b622f407c639%3A0x8e8e2a10ecb1bdba!2sABEC%20Exhibitions%20%26%20Conferences%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1692895548362!5m2!1sen!2sin"
          style={{
            width: "130px",
            height: "100px",
            style: "border:0",
            allowfullscreen: "",
            loading: "lazy",
            referrerpolicy: "no-referrer-when-downgrade",
          }}
        ></iframe>
      </div>
    </div>
  );
};

export const Footer = () => {
  const isLaptopScreen = useMediaQuery({ minWidth: 768 });

  {
    /* Hello  Changes for Email updates */
  }

  const [emailUpdates, setEmailUpdates] = useState("");
  const handleSubmit = () => {
    const data = {
      Email: emailUpdates,
    };
    const formArray = [];
    formArray.push(data);
    try {
      formArray.forEach((data) => {
        if (data) {
          axios
            .post(
              "https://sheet.best/api/sheets/cfebc912-2313-454d-b3eb-bd9c109a66ca",
              data
            )
            .then(() => {
              setEmailUpdates("");
              alert(
                "Thank you for subscribing to our newsletter! You will receive the latest updates and news directly in your inbox."
              );
            })
            .catch((err) => console.error(err.response.data));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div
        className="lg:h-72 py-10 px-6"
        style={{ backgroundColor: "#222222" }}
      >
        <div className="lg:w-2/5 m-auto text-center text-xl lg:text-3xl font-normal text-white font-Montserrat">
          Register yourself now
        </div>
        <div className="w-4/5 text-xs m-auto text-center text-white font-normal mt-4 lg:text-xl lg:mt-6 lg:w-3/5 font-Montserrat">
          Register yourself for future references & extra updates day by day.
        </div>
        <div
          className="w-76 m-auto h-10 md:w-1/2 lg:h-14 mt-6 flex items-center justify-around pl-4 lg:w-2/6"
          style={{
            backgroundColor: "#343434",
            borderRadius: "50px",
          }}
        >
          <div className="w-7 h-5 text-center lg:w-9 lg:h-7">
            <AiOutlineMail
              className="w-full h-full"
              style={{ color: "#777777" }}
            />
          </div>

          <input
            type="email"
            name=""
            value={emailUpdates}
            onChange={(e) => setEmailUpdates(e.target.value)}
            placeholder="Enter your email address"
            className="w-3/5 m-auto text-xs outline-none lg:w-8/12 lg:text-lg font-Playfair-Display"
            style={{
              backgroundColor: "#343434",
              color: "white",
            }}
          />
          <button
            onClick={handleSubmit}
            className="text-white h-full text-xs rounded-r-3xl px-2 py-1 text-center flex items-center cursor-pointer lg:text-base lg:px-4 lg:py-2 font-Playfair-Display"
            style={{
              backgroundColor: "#111111",
            }}
          >
            REGISTER
          </button>
        </div>
      </div>

      {isLaptopScreen ? (
        <div
          className="flex justify-between lg:p-10 md:px-4 md:p-6"
          style={{ backgroundColor: "#303030" }}
        >
          <div>
            <img
              src={
                "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694610018/AceTech%20Website/logo_footer_w8r6hv.png"
              }
              alt="logo"
              className="md:w-40 md:h-44 lg:w-48 lg:h-52 block md:ml-2 lg:ml-12 lg:-mt-12 md:-mt-6"
            />
          </div>
          <div className="md:ml-4">
            <div className="lg:w-96 lg:h-28 md:w-64 md:text-base lg:text-lg font-medium text-white font-Montserrat">
              530, Laxmi Plaza, Laxmi Industrial Estate, New Link Road, Andheri
              West, Mumbai – 400053, India
            </div>
            <div className="flex justify-start items-center text-white mt-3">
              <a
                href="https://wa.me/+919833237174"
                target="_blank"
                rel="noreferrer"
              >
                <BsWhatsapp className="lg:w-6 lg:h-6 md:w-4 md:h-4" />
              </a>
              <div
                className="lg:text-base md:text-sm font-medium lg:ml-3 md:ml-2"
                style={{
                  fontFamily: "montserrat",
                }}
              >
                <a
                  href="https://wa.me/+919833237174"
                  target="_blank"
                  rel="noreferrer"
                >
                  +91 98332-37174
                </a>
              </div>
              <div
                className="lg:text-lg font-semibold lg:ml-14 md:ml-6"
                style={{
                  fontFamily: "montserrat",
                }}
              >
                <a
                  href="https://wa.me/+919833237174"
                  target="_blank"
                  rel="noreferrer"
                >
                  Miraz Qureshi
                </a>
              </div>
            </div>

            {/* <div className="flex justify-start items-center text-white mt-3">
              <a href="tel:+919833237174" target="_blank" rel="noreferrer">
                <BsTelephoneFill className="lg:w-6 lg:h-6 md:w-4 md:h-4" />
              </a>
              <div
                className="lg:text-base md:text-sm font-medium lg:ml-3 md:ml-2"
                style={{
                  fontFamily: "montserrat",
                }}
              >
                <a href="tel:+919833237174" target="_blank" rel="noreferrer">
                  +91 98332-37174
                </a>
              </div>
              <div
                className="lg:text-lg font-semibold lg:ml-14 md:ml-6"
                style={{
                  fontFamily: "montserrat",
                }}
              >
                <a href="tel:+919833237174" target="_blank" rel="noreferrer">
                  Miraz Qureshi
                </a>
              </div>
            </div> */}

            <div className="flex justify-start items-center text-white mt-3">
              <a href="tel:+919324553996" target="_blank" rel="noreferrer">
                <BsTelephoneFill className="lg:w-6 lg:h-6 md:w-4 md:h-4" />
              </a>
              <div
                className="lg:text-base md:text-sm font-medium lg:ml-3 md:ml-2"
                style={{
                  fontFamily: "montserrat",
                }}
              >
                <a href="tel:+919324553996" target="_blank" rel="noreferrer">
                  +91 93245-53996
                </a>
              </div>
              <div
                className="lg:text-lg font-semibold lg:ml-14 md:ml-6"
                style={{
                  fontFamily: "montserrat",
                }}
              >
                <a href="tel:+919324553996" target="_blank" rel="noreferrer">
                  Shamsher Ali
                </a>
              </div>
            </div>

            <div className="flex justify-start items-center text-white mt-8">
              <a href="#" target="_blank">
                <AiOutlineMail className="lg:w-6 lg:h-6 md:w-4 md:h-4" />
              </a>
              <div
                className="lg:text-base md:text-sm font-medium lg:ml-3 md:ml-2"
                style={{
                  fontFamily: "montserrat",
                }}
              >
                <a
                  href="mailto:miraz.q@abec.asia"
                  target="_blank"
                  rel="noreferrer"
                >
                  miraz.q@abec.asia
                </a>
              </div>
              <div
                className="lg:text-base md:text-sm font-medium ml-8"
                style={{
                  fontFamily: "montserrat",
                }}
              >
                <a
                  href="mailto:ali.s@abec.asia"
                  target="_blank"
                  rel="noreferrer"
                >
                  ali.s@abec.asia
                </a>
              </div>
            </div>
          </div>

          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15077.408793284514!2d72.8292998!3d19.1360545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b622f407c639%3A0x8e8e2a10ecb1bdba!2sABEC%20Exhibitions%20%26%20Conferences%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1692895548362!5m2!1sen!2sin"
              style={{
                width: "100%",
                height: "200px",
                style: "border:0",
                allowfullscreen: "",
                loading: "lazy",
                referrerpolicy: "no-referrer-when-downgrade",
              }}
            ></iframe>
          </div>
        </div>
      ) : (
        <MobileFooter />
      )}

      <div className="px-2 py-4 lg:p-6 font-Montserrat bg-[#303030]">
        <div className="text-center text-xs text-white font-normal lg:text-lg">
          ©2023-2024 The ACETECH | All rights are reserved
        </div>
      </div>
    </div>
  );
};
