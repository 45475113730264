import React, { useEffect, useState } from "react";
import "../../App.css";

import Bg from "../../assets/Shuvam/Rectangle-404.png";
import QuestionMark from "../../assets/Shuvam/QuestionMark.png";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../Navbar";
import { SecondaryButton } from "../CTAButtons";
import BangaloreSeats from "../../assets/Shuvam/Seats/Bangalore.png";

const BookingPlace = ({ slNo, stallName, sqFt, noOfPeople }) => {
  return (
    <>
      <div>
        <ul className="flex justify-between items-center relative mx-4">
          <li className="font-[400] font-Montserrat text-[10px] lg:text-[26px] leading-[20px] lg:leading-[31px] text-white">
            {slNo}
          </li>
          <li className="font-[400] font-Montserrat text-[10px] lg:text-[26px] leading-[20px] lg:leading-[31px] text-white">
            {stallName}
          </li>
          <li className="font-[400] font-Montserrat text-[10px] lg:text-[26px] leading-[20px] lg:leading-[31px] text-white">
            {sqFt}
          </li>
          <li className="font-[400] font-Montserrat text-[10px] lg:text-[26px] leading-[20px] lg:leading-[31px] text-white">
            {noOfPeople}
          </li>
        </ul>
        <div className="hidden lg:block border-t border-gray-500/80 my-4 absolute w-[65%] right-36"></div>
      </div>
    </>
  );
};

const CityButton = ({ city, onClick, isCity }) => {
  return (
    <>
      <div className="w-full h-auto flex items-center justify-center text-center">
        <button
          className={`w-[85%] lg:w-full mx-2 ${
            isCity && "bg-[#FCFCFC] bg-opacity-20"
          } h-full lg:h-[80%] font-[400] text-[14px] leading-[18px] lg:text-[1.5em] lg:leading-none font-Playfair-Display text-white rounded-full py-1 lg:py-0 p-0 lg:px-2 lg:mx-2 mx-1  `}
          onClick={onClick}
        >
          <h1 className={`${isCity && "animate-fade-up"}`}>{city}</h1>
        </button>
      </div>
    </>
  );
};

const Booking = () => {
  const [isBangalore, setIsBangalore] = useState(true);
  const [isMumbai, setIsMumbai] = useState(false);
  const [isHyderabad, setIsHyderabad] = useState(false);
  const [isDelhi, setIsDelhi] = useState(false);

  const [imageBg, setImageBg] = useState(Bg);
  const [seatsImg, setSeatsImg] = useState(BangaloreSeats);

  const [stallName, setStallName] = useState("");

  const navigate = useNavigate();

  const handleBangalore = () => {
    setIsBangalore(true);
    setIsDelhi(false);
    setIsHyderabad(false);
    setIsMumbai(false);
  };
  const handleMumbai = () => {
    setIsBangalore(false);
    setIsDelhi(false);
    setIsHyderabad(false);
    setIsMumbai(true);
  };
  const handleHyderabad = () => {
    setIsBangalore(false);
    setIsDelhi(false);
    setIsHyderabad(true);
    setIsMumbai(false);
  };
  const handleDelhi = () => {
    setIsBangalore(false);
    setIsDelhi(true);
    setIsHyderabad(false);
    setIsMumbai(false);
  };

  useEffect(() => {
    if (isBangalore) {
      setImageBg(
        "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694610559/AceTech%20Website/City/Bangalore_i6fctt.png"
      );
      setStallName("BENGALURU Stall abc");
      setSeatsImg(
        "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694618467/AceTech%20Website/City/Seats/Bangalore_su7i5z.png"
      );
    } else if (isDelhi) {
      setImageBg(
        "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694610559/AceTech%20Website/City/Delhi_dswgyi.png"
      );
      setStallName("DELHI Stall abc");
      setSeatsImg(
        "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694618467/AceTech%20Website/City/Seats/Delhi_dmq7uk.png"
      );
    } else if (isMumbai) {
      setImageBg(
        "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694610561/AceTech%20Website/City/Mumbai_bafwk5.png"
      );
      setStallName("MUMBAI Stall abc");
      setSeatsImg(
        "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694618467/AceTech%20Website/City/Seats/Mumbai_klhzbd.png"
      );
    } else if (isHyderabad) {
      setImageBg(
        "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694610559/AceTech%20Website/City/Hyderabad_nc8j3h.png"
      );
      setStallName("HYDERABAD Stall abc");
      setSeatsImg(
        "https://res.cloudinary.com/dfxwoaico/image/upload/q_auto/v1694618467/AceTech%20Website/City/Seats/Hyderabad_ypivid.png"
      );
    }
  }, [isBangalore, isDelhi, isHyderabad, isMumbai]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div className="h-auto">
      {/* <div className="h-[90vh] lg:h-auto"> */}
      <div className="h-[75%] lg:h-full bg-black/50 relative ">
        {isBangalore && (
          <img
            src={imageBg}
            alt=""
            className={`w-full h-full absolute top-0 left-0 -z-10 object-cover ${
              isBangalore && "animate-fade-up"
            }`}
          />
        )}
        {isDelhi && (
          <img
            src={imageBg}
            alt=""
            className={`w-full h-full absolute top-0 left-0 -z-10 object-cover ${
              isDelhi && "animate-fade-up"
            }`}
          />
        )}
        {isMumbai && (
          <img
            src={imageBg}
            alt=""
            className={`w-full h-full absolute top-0 left-0 -z-10 object-cover ${
              isMumbai && "animate-fade-up"
            }`}
          />
        )}
        {isHyderabad && (
          <img
            src={imageBg}
            alt=""
            className={`w-full h-full absolute top-0 left-0 -z-10 object-cover ${
              isHyderabad && "animate-fade-up"
            }`}
          />
        )}
        <div className="w-full h-full flex flex-col">
          <div className="h-[10%]">
            <Navbar />
          </div>
          <div className="flex-1 w-full h-[80%] lg:h-full flex flex-col  mt-20 gap-10 lg:gap-0 z-1 mb-[4em]">
            <div>
              <h1 className="text-[24px] lg:text-3xl leading-none lg:leading-[52px] font-[400] font-Playfair-Display mt-10 lg:mt-5 text-center text-white">
                HALL LAYOUT
              </h1>
            </div>
            <img
              src={seatsImg}
              alt=""
              className="w-[80%] lg:w-[50%] h-[280px] max-h-[400px] lg:h-[25rem] lg:max-h-[30rem]  mx-auto object-contain"
            />
            <div className="mt-10 w-full h-full lg:h-[60%] mx-auto flex flex-col lg:gap-0">
              <div
                // className="text-white w-full h-[100px] lg:h-12 bg-[#FCFCFC] rounded-full bg-opacity-20 mx-0 lg:mx-auto mt-5 lg:mt-1   grid-cols-2 grid-rows-2 lg:flex"
                className="text-white w-full h-[50px] lg:h-12 bg-[#FCFCFC] rounded-full bg-opacity-20 mx-0 lg:mx-auto mt-5 lg:mt-5  grid grid-cols-2 grid-rows-2 lg:flex"
              >
                <CityButton
                  city="Bengaluru"
                  isCity={isBangalore}
                  onClick={handleBangalore}
                />

                <CityButton
                  city="Mumbai"
                  isCity={isMumbai}
                  onClick={handleMumbai}
                />
                <CityButton
                  city="Delhi"
                  isCity={isDelhi}
                  onClick={handleDelhi}
                />

                <CityButton
                  city="Hyderabad"
                  isCity={isHyderabad}
                  onClick={handleHyderabad}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-32 bg-black/90 flex flex-col pt-20">
        <div>
          <h1 className="font-[400] font-Playfair-Display text-[20px] lg:text-[40px] leading-[52.75px] text-[#F9F9F9] text-center">
            STALLS-AVAILABLE
          </h1>
        </div>

        <div className="px-2 lg:px-36 pt-20">
          <ul className="flex justify-between items-center">
            <li className="font-[400] font-Playfair-Display text-[16px] lg:text-[30px] leading-[21px] lg:leading-[39px] text-white">
              S.No
            </li>
            <li className="font-[400] pl-[5px] lg:pl-0 font-Playfair-Display text-[16px] lg:text-[30px] leading-[21px] lg:leading-[39px] text-white">
              Name
            </li>
            <li className="font-[400] font-Playfair-Display pl-[80px] lg:pl-[187px] text-[16px] lg:text-[30px] leading-[21px] lg:leading-[39px] text-white">
              Size
            </li>
            <li className="font-[400] font-Playfair-Display text-[16px] lg:text-[30px] leading-[21px] lg:leading-[39px] text-white">
              Volume
            </li>
          </ul>
          <div className="border-t border-gray-500/80 my-4"></div>
        </div>

        <div className="px-2 lg:px-36 pt-3 lg:pt-20 flex flex-col gap-5 lg:gap-12">
          <BookingPlace
            slNo={1}
            stallName={stallName}
            sqFt={20000}
            noOfPeople={2000}
          />
          <BookingPlace
            slNo={2}
            stallName={stallName}
            sqFt={20000}
            noOfPeople={2000}
          />
          <BookingPlace
            slNo={3}
            stallName={stallName}
            sqFt={20000}
            noOfPeople={2000}
          />
          <BookingPlace
            slNo={4}
            stallName={stallName}
            sqFt={20000}
            noOfPeople={2000}
          />
          <BookingPlace
            slNo={5}
            stallName={stallName}
            sqFt={20000}
            noOfPeople={2000}
          />
          <BookingPlace
            slNo={6}
            stallName={stallName}
            sqFt={20000}
            noOfPeople={2000}
          />
          <BookingPlace
            slNo={7}
            stallName={stallName}
            sqFt={20000}
            noOfPeople={2000}
          />
        </div>
      </div>
      <div className="bg-black h-[70px] lg:h-24 flex justify-center items-center">
        <div className="mb-5 lg:mb-2">
          <SecondaryButton onClick={() => navigate("/contact")}>
            More
          </SecondaryButton>
        </div>
      </div>

      <div className="h-[500px] bg-black/90 flex flex-col gap-10 items-center justify-center">
        <h1 class="font-[600] text-[22px] lg:text-[40px] text-transparent bg-clip-text bg-gradient-to-br from-[#F9F9F9] to-[#B8B8B8]">
          Want to Enquire something?
        </h1>
        <img
          src={QuestionMark}
          alt=""
          className="w-[63px] lg:w-[114px] h-[88px] lg:h-[158px]"
        />
        <button
          className="w-[118px] lg:w-[211px] h-[33px] lg:h-[59px] rounded-[50px] text-[15px] lg:text-[28px] font-[400] font-Playfair-Display leading-[20px] lg:leading-[37px] text-white bg-[#45494F]"
          onClick={() => navigate("/contact")}
        >
          Enquire Now
        </button>
      </div>
    </div>
  );
};

export default Booking;
