import React, { useEffect } from "react";
import Header from "../Components/Header";
import { Benfitis } from "../Components/Benfitis";
import { Partners } from "../Components/Partners";
import { Footer } from "../Components/Footer";
import { CarouselComponent } from "../Components/Carousel";
import { Event } from "../Components/Event";
import { Segment } from "../Components/Segment";
export const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <Header />
      <Benfitis />
      <Segment />
      <Event />
      <CarouselComponent />
      <Partners />
      <Footer />
    </>
  );
};
