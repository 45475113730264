import React, { useState } from "react";
import sofaIcon from "../assets/sofa icon.png";
import bathroomIcon from "../assets/bathroom icon.png";
import homeIcon from "../assets/home icon.png";
import wireIcon from "../assets/electric icon.png";
import tileIcom from "../assets/tile icon.png";
import doorIcom from "../assets/door icon.png";
import acIcon from "../assets/ac icon.png";
import kitchenIcon from "../assets/kitchen icon.png";
import paintIcon from "../assets/paint icon.png";
import fireIcon from "../assets/fire icon.png";
import decoIcon from "../assets/decorative icon.png";
import escaIcon from "../assets/escator icon.png";
import glassIcon from "../assets/glass icon.png";
import harwareIcon from "../assets/hardware icon.png";
import landIcon from "../assets/landscap icon.png";
import pipesIcon from "../assets/pipes icon.png";
import roofIcon from "../assets/roofing icon.png";
import steelIcon from "../assets/steel icon.png";
import stonesIcon from "../assets/stones icon.png";
import switchICon from "../assets/switch icon.png";
import waterIcon from "../assets/water icon.png";
import woodIcon from "../assets/wood icon.png";
import "../Styles/Segment.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const CoverComponent = ({ bgCover, num, name, title }) => {
  return (
    <div
      className="bg-cover h-screen"
      style={{
        backgroundImage: `url(${bgCover})`,
      }}
    >
      <div className="">
        <div className="flex justify-center items-center">
          <div className="text-[115px] font-normal text-white text-center mt-0 font-Playfair-Display">
            {num}
          </div>
        </div>
        <div
          className="text-4xl font-bold text-white text-center font-Playfair-Display"
          style={{
            fontFamily: "Montserrat",
          }}
        >
          {name}
        </div>
      </div>

      <div className="lg:w-1/2 m-auto leading-relaxed text-3xl lg:text-5xl text-white text-center pt-24 font-Playfair-Display">
        {title}
      </div>
    </div>
  );
};

const IconComponent = ({ img, title, alt }) => {
  return (
    <div>
      <div
        className="w-16 h-16 lg:w-28 lg:h-28 p-4 lg:p-8 m-auto rounded-full"
        style={{ backgroundColor: "#BDBDBD" }}
      >
        <img src={img} alt={alt} />
      </div>
      <div
        className="text-white text-center text-xs pt-2 font-medium"
        style={{
          fontFamily: "montserrat",
        }}
      >
        {title}
      </div>
    </div>
  );
};

export const SegmentMobileScreen = () => {
  const [selectedIcon, setSelectedIcon] = useState(0);

  const handleIconClick = (index) => {
    setSelectedIcon(index);
  };

  const images = [
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694521475/AceTech%20Website/Bath_xp7gcz.jpg"
      }
      num={22}
      name={"Segment"}
      title={"BATH & SANITATION"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525299/AceTech%20Website/Furniture_jwxq6w.jpg"
      }
      num={22}
      name={"Segment"}
      title={"INTERNATIONAL FURNITURE"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525436/AceTech%20Website/homeBG_anmcvc.jpg"
      }
      num={22}
      name={"Segment"}
      title={"HOME & OFFICE AUTOMATION"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525907/AceTech%20Website/wires_dqsbt3.jpg"
      }
      num={22}
      name={"Segment"}
      title={"ELECTRICALS, WIRE & CABLES"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525757/AceTech%20Website/tiles_odaewu.jpg"
      }
      num={22}
      name={"Segment"}
      title={"TILES & CERAMICS"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525174/AceTech%20Website/Doors_tr9vsh.jpg"
      }
      num={22}
      name={"Segment"}
      title={" DOORS & WINDOWS"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694521403/AceTech%20Website/AC_czvi3t.jpg"
      }
      num={22}
      name={"Segment"}
      title={"AIR CONDITIONING & REFRIGERATION"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525473/AceTech%20Website/kitchen_ty0l8g.jpg"
      }
      num={22}
      name={"Segment"}
      title={"INTERNATIONAL CUCINE & KITCHEN TECHNOLOGIES"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694526218/AceTech%20Website/paint_photo_fqpcup.jpg"
      }
      num={22}
      name={"Segment"}
      title={"PAINTS, COATS & WALL COVERINGS"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525644/AceTech%20Website/saftey_ynfhsa.jpg"
      }
      num={22}
      name={"Segment"}
      title={"SAFTEY, SECURITY & FIRE PROTECTION"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525095/AceTech%20Website/decorative_jhtvxe.jpg"
      }
      num={22}
      name={"Segment"}
      title={"DECORATIVE & ARCHITECTURAL LIGHTING"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525347/AceTech%20Website/glass_ryxdcs.jpg"
      }
      num={22}
      name={"Segment"}
      title={"GLASS GLAZING & FACADES"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525717/AceTech%20Website/stones_ei9sp5.jpg"
      }
      num={22}
      name={"Segment"}
      title={"NATURAL ENGINEERED MARBEL & STONES"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525678/AceTech%20Website/steel_t5nn97.jpg"
      }
      num={22}
      name={"Segment"}
      title={"STEEL & CONCRETE"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525516/AceTech%20Website/pipes_higq91.jpg"
      }
      num={22}
      name={"Segment"}
      title={"PLUMBING PIPES & FITINGS"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525613/AceTech%20Website/landscapes_byjuk7.jpg"
      }
      num={22}
      name={"Segment"}
      title={"LANDSCAPES"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525872/AceTech%20Website/water_v5ro6h.jpg"
      }
      num={22}
      name={"Segment"}
      title={"WATER TECHNOLOGIES"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525251/AceTech%20Website/elevators_c7t5cg.jpg"
      }
      num={22}
      name={"Segment"}
      title={"ELEVATORS & ESCALATORS"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525941/AceTech%20Website/wood_q8osdt.jpg"
      }
      num={22}
      name={"Segment"}
      title={"WOOD, VENEER & FLOORINGS"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525550/AceTech%20Website/roofing_vptfih.jpg"
      }
      num={22}
      name={"Segment"}
      title={"ROOFING & CLADDING"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525802/AceTech%20Website/switch_blfkxf.jpg"
      }
      num={22}
      name={"Segment"}
      title={"SWITCHES & SWITCHGEAR"}
    />,
    <CoverComponent
      bgCover={
        "https://res.cloudinary.com/da05bn5ub/image/upload/q_auto/v1694525391/AceTech%20Website/hardware_hxfu9z.jpg"
      }
      num={22}
      name={"Segment"}
      title={"HARDWARE & FITTINGS"}
    />,
  ];

  const icons = [
    <IconComponent
      img={bathroomIcon}
      title={"BATH & SANITATION"}
      alt={"BATH"}
    />,
    <IconComponent
      img={sofaIcon}
      title={"International Funriture"}
      alt={"Funriture"}
    />,
    <IconComponent
      img={homeIcon}
      title={"HOME & OFFICE AUTOMATION"}
      alt={"HOME"}
    />,
    <IconComponent
      img={wireIcon}
      title={"ELECTRICALS, WIRE & CABLES"}
      alt={"ELECTRICALS"}
    />,
    <IconComponent img={tileIcom} title={"TILES & CERAMICS"} alt={"TILES"} />,
    <IconComponent img={doorIcom} title={"DOORS & WINDOWS"} alt={"DOORS"} />,
    <IconComponent
      img={acIcon}
      title={"AIR CONDITIONING & REFRIGERATION"}
      alt={"REFRIGERATION"}
    />,
    <IconComponent
      img={kitchenIcon}
      title={"INTERNATIONAL CUCINE & KITCHEN TECHNOLOGIES"}
      alt={"KITCHEN"}
    />,
    <IconComponent
      img={paintIcon}
      title={"PAINTS, COATS & WALL COVERINGS"}
      alt={"PAINTS"}
    />,
    <IconComponent
      img={fireIcon}
      title={"Safety, Security & fire Protection"}
      alt={"Safety"}
    />,
    <IconComponent
      img={decoIcon}
      title={"Decorative & Architectural Lighting"}
      alt={"Decorative"}
    />,
    <IconComponent
      img={glassIcon}
      title={"Glass Glazing & Facades"}
      alt={"Glass"}
    />,
    <IconComponent
      img={stonesIcon}
      title={"Natural Engineered Marble Stones"}
      alt={"Stones"}
    />,
    <IconComponent img={steelIcon} title={"Steel & Concrete"} alt={"Steel"} />,
    <IconComponent
      img={pipesIcon}
      title={"Plumbing Pipes & Fittings"}
      alt={"Pipes"}
    />,
    <IconComponent img={landIcon} title={"Landscapes"} alt={"Landscapes"} />,
    <IconComponent
      img={waterIcon}
      title={"Water Technologies"}
      alt={"Water"}
    />,
    <IconComponent
      img={escaIcon}
      title={"Elevators & Escalators"}
      alt={"Elevators"}
    />,
    <IconComponent
      img={woodIcon}
      title={"Wood, Veneer & Floorings"}
      alt={"Wood"}
    />,
    <IconComponent
      img={roofIcon}
      title={"Roofing & Cladding"}
      alt={"Roofing"}
    />,
    <IconComponent
      img={switchICon}
      title={"Switches & Switchgear"}
      alt={"Switches"}
    />,
    <IconComponent
      img={harwareIcon}
      title={"Harware & Fittings"}
      alt={"Harware"}
    />,
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="w-full relative">
        <div className="w-full h-screen">{images[selectedIcon]}</div>
        <div className="w-full h-40 grid justify-center items-center absolute bottom-0 left-1/2 transform -translate-x-1/2 backdrop-blur-lg">
          <Carousel
            responsive={responsive}
            swipeable={true}
            arrows={false}
            centerMode
            infinite={true}
            className="w-full h-40 grid justify-center items-center absolute bottom-0 left-1/2 transform -translate-x-1/2 backdrop-blur-lg flex flex-no-wrap overflow-x-scroll"
          >
            {icons.map((el, index) => (
              <div>
                <div
                  key={el}
                  onClick={() => handleIconClick(index)}
                  className="w-full h-40 py-10 z-50"
                >
                  <div>{el}</div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
