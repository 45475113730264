import React, { useEffect } from "react";
import Number from "../Components/Number";
import { Info } from "../Components/Info";
import { Footer } from "../Components/Footer";
import Slider from "../Components/Slider";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div>
      <Number />
      <Slider />
      <Info />
      <Footer />
    </div>
  );
};
